<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import loginRegModal from "../popUpModals/loginReg";
import termsAndCondition from "../popUpModals/termsAndCondition";
import footerLayout from "../../layouts/footerLayout";
import appConfig from "@/app.config";
import ClassOnScroll from 'vue-class-on-scroll'
import simplebar from "simplebar-vue";

import Drawer from "vue-simple-drawer"

import jwt_decode from "jwt-decode";

import PincodeInput from 'vue-pincode-input';

import VueCountdown from '@chenfengyuan/vue-countdown';

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Vue from 'vue';
// import registerCopyVue from '../account/register - Copy.vue';

import { DropdownPlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)

export default {
    page: {
        title: "Cloud Kitch",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        // Layout,
        // PageHeader,
        loginRegModal,
        termsAndCondition,
        footerLayout,
        simplebar,
        PincodeInput,
        VueCountdown,
        VueGoogleAutocomplete,
        Drawer
    },
     directives: {
        'class-on-scroll': ClassOnScroll,
    },
    data() {
        return {
            openMenuDrawer: false,
            emailIDforReg:'',
            searching: 0,
            changedInputValue:'',
            isValNum:0,
            countryCode:'+91',
            email:'',
            contact:'',
            code:'',
            show: false,
            counting: false,
            variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
            // headerBgVariant: 'dark',
            // headerTextVariant: 'light',
            // bodyBgVariant: 'light',
            // bodyTextVariant: 'dark',
            footerBgVariant: 'light',
            footerTextVariant: 'dark',
            isHidden: true,
            authError: '',
            isAuthError: false,
            regName:'',
            regEmail:'',
            regCorporateCode:'',
            regerror:'',
            hiddenOTPReg: true,
            codeReg: '',
            corporatesBranches: undefined,
            selectedBranchID:'',
            copBranchError:'',
            regSendOTPData: 0,
            regVerifyOTPData: 0,
            sendOTPData: 0,
            verifyOTPData: 0,
            userName:'',
            isUserFetched:0,
            canUserLogout:0,
            localStorageUserData: '',
            params: {
                client_id: "855252858658-sihf66i67ut7cdul3hfm132b95r8agct.apps.googleusercontent.com"
            },
            // only needed if you want to render the button with the google ui
            renderParams: {
                width: 250,
                height: 50,
                longtitle: true
            }
        }
    }, 
    mounted()
    {
        //  Vue.axios.get('/get_user')
        //         .then((res)=>{
        //      console.log(res);
        //  });
        // localStorage.clear();
        // localStorage.removeItem('placesAddress');

        this.getUserData();
        
        if (JSON.parse(localStorage.getItem('placesAddress')) !== null) {
            this.getMealTypePageAccess();
		}
        this.autoRefreshToken();

        //  if (this.$userData !== null) {
		// 	this.localStorageUserData = this.$userData;
		// 	this.userName = this.$userData.userName;
		// 	this.isCorporateUser = this.$userData.isCorporateUser;
		// }
    },
    methods: {
        autoRefreshToken()
        {
            if (localStorage.getItem('uTid') && localStorage.getItem('uTid') != null) 
            {
                var getExp = jwt_decode(localStorage.getItem('uTid'));
                var currentDate  = new Date();
                var tokenExpire = new Date(getExp.exp * 1000);
                var difference = tokenExpire.getTime() - currentDate.getTime(); // This will give difference in milliseconds;
                var resultInMinutes = Math.round(difference / 60000);
                if(resultInMinutes <= 15 && resultInMinutes >= 0)
                {
                    this.refreshTokens();
                }
                else if (resultInMinutes < 0) {
                    this.refreshTokens();
                    // localStorage.clear();
                    // Vue.prototype.$userData = '';
                    // Vue.prototype.$placesAddress = '';
                    // // this.$router.push('/');
                    // window.location.href = '/';
                }
            }
        },

        refreshTokens()
        {
            // alert();
            this.axios.get('/autoRefreshToken')
            .then((response)=>{
                // console.log(response);
                let status = response.status;
                if(status == 200 && response.data.status == 200 && response.data.refreshedToken && response.data.refreshedToken != '')
                {
                    console.log(response.data.refreshedToken);
                    var userData = {
                        "utid":response.data.refreshedToken,
                    }
                    localStorage.setItem('uTid',JSON.stringify(userData));
                    location.reload();
                } // if token is expired so it can be refreshed
            }).catch((error) => {
                // console.log(error);
            });
        },

        toggleMenuDrawer() {
            this.openMenuDrawer = !this.openMenuDrawer
        },
        openLoginModal()
        {
            this.openMenuDrawer = !this.openMenuDrawer;
            this.$bvModal.show("modal-login");
        },
        getUserData()
        {
            this.axios.get('/getUserDeatils')
            .then((response)=>{
                // console.log(response);
                // if(response.data.token && response.data.token != '')
                // {
                // 	var userData = {
                //             "utid":response.data.token,
                //         }
                //         localStorage.setItem('uTid',JSON.stringify(userData));
                // } // if token is expired so it can be refreshed
                let status = response.status;
                if(status == 200 && response.data.status == 200)
                {
                    // console.log(this.$placesAddress);
                    // console.log(response.data.userData.placesAddress);
                    Vue.prototype.$isExtMob = response.data.userData.isFromExtMob ? response.data.userData.isFromExtMob :  0;
                    localStorage.setItem('isExtMob',JSON.stringify(this.$isExtMob));
                    if(response.data.userData.placesAddress && this.$placesAddress == null)
                    {
                        localStorage.setItem('placesAddress',JSON.stringify(response.data.userData.placesAddress)); 
                        this.getMealTypePageAccess();
                    }
                    this.isUserFetched = 1;
                    this.canUserLogout = response.data.userData.isFromExtMob ? response.data.userData.isFromExtMob :  0;
                    if (response.data.userData.userName) {
                        this.userName = response.data.userData.userName;
                        this.corporateLogo = response.data.userData.corporateLogo;
                        Vue.prototype.$isUserLoggedIn  = 1; 
                        Vue.prototype.$corporateLogo  = response.data.userData.corporateLogo; 
                    }
                    else
                    {
                        this.userName = '';
                        Vue.prototype.$isUserLoggedIn  = ''; 
                        Vue.prototype.$corporateLogo  = '';
                    }
                    if(response.data.userData.isTncAgreed == 0)
                    {
                        this.$bvModal.show("modal-termsCondition");
                    }
                }
                else
                {
                    this.isUserFetched = 0;
                    // localStorage.clear();
                }
                // this.cartStatus = response.data.cartdetails;
            }).catch((error) => {
                // localStorage.clear();
                console.log(error.response.data.message);
            });
        },
        logout()
        {
            this.$confirm("Are you sure you want to logout?","Logout","")
            .then(() => {
                localStorage.clear();
                Vue.prototype.$userData = '';
                Vue.prototype.$placesAddress = '';
                // this.$router.push('/');
                location.reload();
            });
        },
        /**
         * When the location found
         * @param {Object} addressData Data of the found location
         * @param {Object} placeResultData PlaceResult object
         * @param {String} id Input container ID
         */
    getAddressData: function (addressData, placeResultData) {
        this.address = addressData;
        if(placeResultData){
            this.addressInput = placeResultData.formatted_address;
            this.latitude = placeResultData.geometry.location.lat();
            this.longitude = placeResultData.geometry.location.lng();  
        }
        //   console.log(this.addressInput);
        //   console.log(this.latitude);
        //   console.log(this.longitude); 
    },
    // If login input is email or Contact number
    findResLocation(){
        if(this.addressInput == '' || this.addressInput == undefined)
        {
            //         console.log(this.addressInput);
            //   console.log(this.latitude);
            //   console.log(this.longitude);
            alert("Please type your address");
        }
        else
        {
            var addressData = {
                "address":this.addressInput,
                "latitude":this.latitude,
                "longitude":this.longitude,
            }
            localStorage.setItem('placesAddress',JSON.stringify(addressData)); 
            this.getMealTypePageAccess();
            // console.log(JSON.parse(localStorage.getItem('placesAddress')));
            // this.$router.push('/restaurants/team_meeting');
        }
    },

    onSuccess(googleUser) {
        // console.log(googleUser);

        // This only gets the user information: id, name, imageUrl and email
        // console.log(googleUser.getBasicProfile());
        var socialData = googleUser.getBasicProfile();
        let socialLogin = JSON.stringify({ socialData: socialData,
            socialType: 1,
            devicetype:1,
        });

        Vue.axios.post('/socialLogin', socialLogin)
        .then((res)=>{
            let status = res.status;
            if(status == 200 && res.data.status==200)
            {
                // console.log(res.data.data.userName);
                var userData = {
                    "userID":res.data.data.userID,
                    "userName":res.data.data.userName,
                    "corporateCode":res.data.data.code,
                    "mobileNo":res.data.data.mobileNo,
                    "isCorporateUser":res.data.data.isCorporateUser,
                    "emailID":res.data.data.emailID,
                    "token":res.data.data.token,
                    "corporateBranchID":res.data.data.corporateBranchID,
                    "corporateID":res.data.data.corporateID,
                    "corporateLogo":res.data.data.corporateLogo,
                    "corporateName":res.data.data.corporateName
                }
                localStorage.setItem('userData',JSON.stringify(userData));
                    this.$bvModal.hide('modal-login');
                if(res.data.isCorporateUser == 1 && (res.data.corporateBranchID == '' || res.data.corporateBranchID == 0)){
                    this.$bvModal.show("modal-corporateBranch");
                    this.emailIDforReg = res.data.emailID;
                    this.geCorporateBranchByID(res.data.corporateID);
                    // this.geCorporateBranchByID();
                }else{
                    location.reload();
                }
            }else{
                console.log(res.data.message)
            }
        });

    },
    getMealTypePageAccess()
    {
        this.searching = 1; 
        //  if (JSON.parse(localStorage.getItem('userData')) !== null) {
        // 	var userID = JSON.parse(localStorage.getItem('userData')).userID;
        // }else{
        //     var userID = "";
        // }
        // let getMealPageFormData = JSON.stringify({ userID: userID,
        // 												//  latitude: latitude,
        // 												//  longitude:longitude
        //                                                 });

        // this.axios.post('/getMealTypePageAccess', getMealPageFormData)
        var isExtMob = 0;
        var address = "";
        var latitude ="";
        var longitude = "";
        if((this.$isExtMob != null && this.$isExtMob == 1) && (this.$placesAddress != null && this.$placesAddress.address != ""))
        {
            var isExtMob = this.$isExtMob;
            var address = this.$placesAddress.address;
            var latitude = this.$placesAddress.latitude;
            var longitude = this.$placesAddress.longitude;
        }
        // let getMealPageFormData = JSON.stringify({ isFromMobExt: isExtMob,
        //                                             address: address,
        // 											latitude: latitude,
        // 											longitude:longitude
        //                                             });

        // this.axios.post('/getMealTypePageAccess', getMealPageFormData)
        this.axios.get('/getMealTypePageAccess',{
            params: {
                isFromMobExt: isExtMob,
                address: address,
                latitude: latitude,
                longitude:longitude
            }
        })
        .then((res)=>{
            let status = res.status;
            if(status == 200 && res.data.status==200)
            {
                this.searching = 0; 
                var menuTabType = res.data.mealTypeTabs[0].menuTabType;
                if(res.data.token != '')
                {
                    var userData = {
                        "utid":res.data.token,
                    }
                    localStorage.setItem('uTid',JSON.stringify(userData));
                }

                if(res.data.mealTypeTabs.length !== 0)
                {
                    localStorage.setItem('mealTypeTabsData',JSON.stringify(res.data));
                    // console.log(localStorage.getItem('mealTypeTabsData'));
                }
                else
                {
                    localStorage.removeItem('mealTypeTabsData');
                }

                switch(menuTabType){
                    case 'team_meeting':
                        this.$router.push({ name: 'restaurants', params: { type:menuTabType } });
                        break;
                    case 'events_party':
                        this.$router.push({ name: 'restaurants', params: { type:menuTabType } });
                        break;
                    case 'restaurants_nearby':
                        this.$router.push({ name: 'restaurants', params: { type:menuTabType } });
                        break;
                    case 'party_orders':
                        this.$router.push({ name: 'restaurants', params: { type:menuTabType } });
                        break;
                    case 'food_court':
                        this.$router.push({ name: 'restaurants', params: { type:menuTabType } });
                        break;
                    case 'corporate_meal':
                        this.$router.push({ name: 'restaurants', params: { type:menuTabType } });
                        break;
                    case 'cafeteria':
                        this.$router.push('/cafeterias');
                        break;
                    default:
                        break;
                }
            }else{
                // console.log(res.data.message);
                this.searching = 0; 
                console.log(res.data.message);
                // this.$router.push('/');
            }
        }).catch((error) => {
            this.searching = 0;
            console.log(error.response.data.message);
        });
    },
    inputValueCheck(event) {
        var inputString = event.target.value;
        this.changedInputValue = inputString;
        var x = inputString.charAt(0);
        var number = !isNaN(parseFloat(x)) && !isNaN(x - 0);
        if(number == true){
            this.isValNum = 1;
        }else{
            this.isValNum = 0;
        }
    },

    // Resend Button timer 
    startCountdown: function () {
        this.counting = true;
    },
    onCountdownEnd: function () {
        this.counting = false;
    },


    // Try to log the user in with the username
    // and password they provided.
    // tryToLogIn(e){
    //     //   let formData = new FormData();  
    //        /*
    //             Add the form data we need to submit
    //         */
    //         // formData.append('phoneNumber', this.changedInputValue);
    //         if(this.changedInputValue == "")
    //         {
    //             this.authError = 'Please fill all the required details';
    //         }
    //         if(this.changedInputValue !== ''){
    //             this.sendOTPData = 1; 
    //             this.authError = '';
    //             if(this.countryCode !== ''){
    //                 // var data = this.countryCode+this.changedInputValue;
    //                 var data = this.changedInputValue;
    //                 // var isMobile = 1;
    //             }else{
    //                 var data = this.changedInputValue;
    //                 // var isMobile = 0;
    //             }
    //             let jsonLogData = JSON.stringify({ contactData: data,
    //                                     });
    //             // formData.append('password', this.posts.password);

    //             e.preventDefault();
    //             // this.axios.post('http://devapi.creativegalileo.com:8080/littlesingham-1.0/api/auth', jsonLogData,{
    //             // headers: {
    //             // // Overwrite Axios's automatically set Content-Type
    //             // 'Content-Type': 'application/json'
    //             // }
    //             // })
    //             Vue.axios.post('/sendOTPLoginUser', jsonLogData)
    //                 .then((result)=>{
    //                     // console.log(result);
    //                     let status = result.status;
    //                      if(status==200 && result.data.status == 200){
    //                          this.startCountdown()
    //                          this.isHidden = false;
    //                          this.sendOTPData = 0; 
    //                         //  this.$router.push('/');
    //                      }
    //                      else{
    //                      this.isAuthError = true;
    //                     this.authError = result.data.message;
    //                     this.sendOTPData = 0;
    //                 }
    //                 //   console.warn(result);
    //             }).catch((error) => {
    //                     this.sendOTPData = 0;
    //                     this.isAuthError = true;
    //                     this.authError = "Something went wrong";
    //                 });
    //         }
        
    // },
    // verifyOTP(e){

    //         if(this.code == '')
    //         {
    //              this.authError = 'Please enter Otp';
    //         }
    //         if(this.code !== ''){
    //             this.verifyOTPData = 1;
    //             if(this.countryCode !== ''){
    //                 // var data = this.countryCode+this.changedInputValue;
    //                 var data = this.changedInputValue;
    //             }else{
    //                 var data = this.changedInputValue;
    //             }
    //             let otpJson = JSON.stringify({ contactData: data,
    //                                         otp: this.code
    //                                         // deviceType: 1,
    //                                         // androidKey: '',
    //                                         // iOSKey: '',
    //                                         // appVersion: ''
    //                                         });
    //             // formData.append('password', this.posts.password);

    //             e.preventDefault();
    //             // this.axios.post('http://127.0.0.1:8000/api/loginWithOTP', otpJson,{
    //             // headers: {
    //             // // Overwrite Axios's automatically set Content-Type
    //             // 'Content-Type': 'application/json'
    //             // }
    //             // })
    //             this.axios.post('/loginWithOTP', otpJson)
    //                 .then((result)=>{
    //                     let status = result.status;
    //                     // console.log(result);
    //                      if(status==200 && result.data.status == 200 && 'token' in result.data.user){
    //                         // this.$session.start()
    //                         // this.$session.set('ck_user_token', result.data.user.token)
    //                         // this.$session.getAll();
    //                         var userData = {
    //                              "userID":result.data.user.userID,
    //                             "userName":result.data.user.userName,
    //                             "corporateCode":result.data.user.code,
    //                             "mobileNo":result.data.user.mobileNo,
    //                             "isCorporateUser":result.data.user.isCorporateUser,
    //                             "emailID":result.data.user.emailID,
    //                             "token":result.data.user.token,
    //                             "corporateBranchID":result.data.user.corporateBranchID,
    //                             "corporateID":result.data.user.corporateID,
    //                             "corporateLogo":result.data.user.corporateLogo,
    //                             "corporateName":result.data.user.corporateName
    //                         }
    //                         localStorage.setItem('userData',JSON.stringify(userData));
    //                         // console.log(localStorage.getItem('userData'));
    //                         //  this.startCountdown()
    //                         //  this.isHidden = false;
    //                         this.verifyOTPData = 0;
    //                          this.$bvModal.hide('modal-login');
    //                         if(result.data.user.isCorporateUser == 1 && (result.data.user.corporateBranchID == '' || result.data.user.corporateBranchID == 0)){
    //                             this.$bvModal.show("modal-corporateBranch");
    //                             this.emailIDforReg = result.data.user.emailID;
    //                             this.geCorporateBranchByID(result.data.data.corporateID);
    //                             // this.geCorporateBranchByID();
    //                         }else{
    //                             //  this.$router.push('/cafeterias');
    //                             //  this.$router.push('/');
    //                             location.reload();
    //                         }
    //                      }
    //                      else{
    //                      this.isAuthError = true;
    //                     this.authError = result.data.message;
    //                     this.verifyOTPData = 0;
    //                 }
    //                 //   console.warn(result);
    //             }).catch((error) => {
    //                     this.verifyOTPData = 0;
    //                     // console.log(error.response.data.message);
    //                     this.isAuthError = true;
    //                     // this.authError = error.response.data.message;
    //                     this.authError = "Something went wrong";
    //                 });
    //         }
        
    // },
    // tryToSignUp(e){
        
    //     if(this.regName == "" || this.regEmail == ""){
    //          e.preventDefault();
    //         this.regerror = "Please Fill all the required fields";
    //     }else{
    //         this.regSendOTPData = 1;
    //         this.regerror = "";
    //          let registerData = JSON.stringify({ userName: this.regName,
    //                                             emailID: this.regEmail,
    //                                             corporateCode: this.regCorporateCode
    //                                         });
    //          this.axios.post('/registerUser', registerData)
    //                 .then((result)=>{
    //                     let status = result.status;
    //                     // console.log(result);
                        
    //                      if(status==200 && result.data.status==200){
    //                         this.hiddenOTPReg = false;
    //                         this.regerror = "";
    //                         this.startCountdown();
    //                         this.regSendOTPData = 0;
    //                      }
    //                      else if(status == 208)
    //                      {
    //                         this.regSendOTPData = 0;
    //                          this.regerror = result.data.message;
    //                      }
    //                      else{
    //                         this.regSendOTPData = 0;
    //                      this.isAuthError = true;
    //                     this.regerror = result.data.message;
    //                 }
    //                 //   console.warn(result);
    //             });
    //     }
    // },
    // verifyOTPReg(e){
    //         if(this.codeReg !== ''){
    //             this.regVerifyOTPData = 0;
                
    //             let otpJson = JSON.stringify({ contactData: this.regEmail,
    //                                             otp: this.codeReg
    //                                         });
    //             // formData.append('password', this.posts.password);

    //             e.preventDefault();
    //             this.axios.post('/regWithOTP', otpJson)
    //                 .then((result)=>{
    //                     let status = result.status;
    //                     // console.log(result);
                            
    //                      if(status==200 && result.data.status==200 &&'token' in result.data.data){
    //                         this.hiddenOTPReg = true;
    //                         var userData = {
    //                             "userID":result.data.data.userID,
    //                             "userName":result.data.data.userName,
    //                             "corporateCode":result.data.data.code,
    //                             "isCorporateUser":result.data.data.isCorporateUser,
    //                             "mobileNo":result.data.data.mobileNo,
    //                             "emailID":result.data.data.emailID,
    //                             "token":result.data.data.token,
    //                             "corporateID":result.data.data.corporateID,
    //                             "corporateBranchID":result.data.data.corporateBranchID,
    //                             "corporateLogo":result.data.data.corporateLogo,
    //                             "corporateName":result.data.data.corporateName

    //                         }
    //                         localStorage.setItem('userData',JSON.stringify(userData));
    //                          this.regVerifyOTPData = 0;
    //                         this.$bvModal.hide('modal-register');
    //                         if(result.data.data.isCorporateUser == 1){
    //                             this.$bvModal.show("modal-corporateBranch");
    //                             // alert();
    //                             this.emailIDforReg = result.data.data.emailID;
    //                             this.geCorporateBranchByID(result.data.data.corporateID);
    //                         }else{
    //                             //  this.$router.push('/cafeterias');
    //                             //  this.$router.push('/');
    //                             location.reload();
    //                         }
                            
    //                      }
    //                      else if(result.data.status == 204)
    //                      {
    //                          this.regVerifyOTPData = 0;
    //                          this.regerror = result.data.message;
    //                      }
    //                      else{
    //                          this.regVerifyOTPData = 0;
    //                      this.isAuthError = true;
    //                   this.regerror = result.data.message;
    //                 }
    //                 //   console.warn(result);
    //             });
    //         }
        
    // },
    // geCorporateBranchByID(CorpID){

    //             // let CorpID = this.$userData.corporateID;
    //             let getCorpBranches = JSON.stringify({ corporateID: CorpID
    //                                         });
    //             // formData.append('password', this.posts.password);

    //             // e.preventDefault();
    //             this.axios.post('/getAllCorporateBranchesByID', getCorpBranches)
    //                 .then((result)=>{
    //                     let status = result.status;
    //                     // console.log(result);
                            
    //                      if(status==200){
    //                          this.corporatesBranches = result.data.corporateBranches;
    //                      }
    //                      else{
    //                         this.isAuthError = true;
    //                         this.regerror = result.data.message;
    //                     }
    //             });
        
    // },
    // corpBranchID(branchID)
    // {
    //     this.selectedBranchID = branchID;
    // },
    // setCorpBranchForUser(e){
    //         if(this.selectedBranchID !== ''){
    //             // let emailID = this.$userData.emailID;
    //             let emailID = this.emailIDforReg;
    //              let updateCorpBrnachID = JSON.stringify({ corpBranchID: this.selectedBranchID,
    //                                                         emailID: emailID
    //                                         });
    //          this.axios.post('/userCorpBranchID', updateCorpBrnachID)
    //                 .then((result)=>{
    //                     let status = result.status;
    //                     // console.log(result);
                        
    //                      if(status==200 && result.data.status==200){
    //                          var userData = {
    //                              "userID": JSON.parse(localStorage.getItem('userData')).userID,
    //                             "userName": JSON.parse(localStorage.getItem('userData')).userName,
    //                             "corporateCode": JSON.parse(localStorage.getItem('userData')).code,
    //                             "mobileNo": JSON.parse(localStorage.getItem('userData')).mobileNo,
    //                             "isCorporateUser": JSON.parse(localStorage.getItem('userData')).isCorporateUser,
    //                             "emailID": JSON.parse(localStorage.getItem('userData')).emailID,
    //                             "token": JSON.parse(localStorage.getItem('userData')).token,
    //                             "corporateBranchID": result.data.corpBranchID,
    //                             "corporateID": JSON.parse(localStorage.getItem('userData')).corporateID,
    //                             "corporateLogo": JSON.parse(localStorage.getItem('userData')).corporateLogo,
    //                             "corporateName":JSON.parse(localStorage.getItem('userData')).corporateName
    //                         }
    //                         localStorage.setItem('userData',JSON.stringify(userData));
    //                         // this.$router.push('/cafeterias');
    //                         // this.$router.push('/');
    //                             location.reload();
    //                      }
    //                      else if(result.data.status == 204)
    //                      {
    //                          this.copBranchError = result.data.message;
    //                      }
    //                      else{
    //                      this.isAuthError = true;
    //                     this.copBranchError = result.message;
    //                 }
    //                 //   console.warn(result);
    //             });
    //         }
    // },
    },
};
</script>

<template>
  <!-- <Layout> -->
    <div>
        <header class="header clearfix element_to_stick headerCk" v-class-on-scroll="{ class: 'sticky', offset: 200 }">
        <div class="container">
            <div class="row">
				<div class="col-md-4 col-lg-4 col-xl-4 noMobile">
            <div id="logo">
                <div class="contact" v-class-on-scroll="{ class: 'contactSticky', offset: 200 }">
                   <!-- <img src="img/logo.svg" width="162" height="35" alt="" class="logo_normal">
                    <img src="img/logo_sticky.svg" width="162" height="35" alt="" class="logo_sticky"> -->
                    <i class="icon_phone"></i><a href="tel:+917710999666" class="contactAnch anchor"><span> +91 771 099 9666</span></a>
                </div>
            </div>
            </div>
				<div class="col-md-4 col-lg-4 col-xl-4 align-center">
					<div id="mainLogo logo">
						<a href="/">
							<img class="mainLogoImg mainLogoHide" v-class-on-scroll="{ class: 'mainLogoShow', offset: 200 }" src="../../templateAssests/img/cklogo.png" height="55" alt="">
						</a>
					</div>
				</div>
            <!-- /top_menu -->
            <a href="#0" class="login_reg align-left" style="top:33px;">
                <i class="icon_phone" style="color:#fff;"></i><a href="tel:+917710999666" class="contactAnch anchor"><span> +91 771 099 9666</span></a>
            </a>
            <!-- <a href="#0" class="open_close"> -->
                <!-- <i @click="toggleMenuDrawer" class="icon_menu"></i> -->
							<!-- <Drawer @close="toggleMenuDrawer" align="left" :maskClosable="true" :closeable="false">
                                             <div v-if="openMenuDrawer">
												 <section class="head_section">
													<div  v-if="userName != ''" class="row">
													 <a href="/profile/my-profile">
														<div class="align-left"> -->
															<!-- <figure> -->
																<!-- <img class="userPlaceImage" src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="50" width="50" alt=""> -->
																<!-- </figure> -->
														<!-- </div>
														<div class="align-left">
															<a href="/profile/my-profile" class="userName" style="margin:8px 0 0 0; color:#212121; font-size:14px;">{{userName}}</a>
														</div>
                                                     </a>
													</div>
													<div v-else class="row">
														<div class="align-left">
															<p class="loginReg" @click="openLoginModal">Login / Register</p>
														</div>
													</div>
												 </section>
												 <hr style="margin:0px;"> -->
												 <!-- headMobileMenu -->

												 <!-- <section class="navigation_section">
													<ul class="dropdown-ul navigation_ul align-left">
														<a href="/"><li><img src="../../templateAssests/img/ckgallery/CloudKitch_logo_solo.png" style="object-fit:contain;margin-right:10px;" width="25" height="25" alt="">Home</li></a>
														<a @click="redirectCart"><li><img src="../../templateAssests/img/ckgallery/Cart.png" width="25" height="25" style="object-fit:contain;margin-right:10px;" alt="">Cart</li></a>
														<a href="/profile/my-orders"><li><i class="icon_bag_alt" style="font-size:20px;margin-right:10px;"></i>My Orders</li></a>
														<a href="/profile/my-subscriptions"><li><i class="mdi mdi-calendar-clock" style="font-size:20px;margin-right:10px;"></i>My Subscriptions</li></a>
														<a href="/profile/my-address"><li><i class="far fa-address-book" style="font-size:20px;margin-right:10px;"></i>My Address</li></a>
														<a href="/profile/my-profile"><li><i class="mdi mdi-account" style="font-size:20px;margin-right:10px;"></i>Profile</li></a>
													</ul> 
												 </section>
												 <hr class="hrBorder"> -->
												 <!-- navigation_section -->

												 <!-- <section class="help_section">
													 <h3 class="helpHead align-left">Help & Support</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<li><a href="/policies/terms-and-condition"><i class="mdi mdi-file-document-multiple-outline" style="font-size:20px;margin-right:10px;"></i>Terms & Conditions</a></li>
														<li><a href="/policies/grievance-policy"><i class="mdi mdi-file-document-outline" style="font-size:20px;margin-right:10px;"></i>Grievance Policy</a></li>
														<li><a href="/policies/cookie-policy"><i class="mdi mdi-cookie-outline" style="font-size:20px;margin-right:10px;"></i>Cookie Policy</a></li>
														<li><a href="/policies/cancellation-refund"><i class="mdi mdi-cash-refund" style="font-size:20px;margin-right:10px;"></i>Cancellation & Refund</a></li>
														<li><a href="#"><i class="icon_mail_alt" style="font-size:20px;margin-right:10px;"></i>Contact Us</a></li>
													</ul> 
												 </section>
												 <hr class="hrBorder"> -->
												 <!-- help_section -->

												 <!-- <section class="other_section" v-if="userName != ''">
													 <h3 class="helpHead align-left">Other</h3>
													<ul class="dropdown-ul navigation_ul align-left">
														<li><a @click="logout"><i class="uil uil-sign-out-alt" style="font-size:20px;margin-right:10px;"></i>Logout</a></li>
													</ul> 
												 </section> -->

												 <!-- <hr class="hrBorder"> -->
												 <!-- navigation_section -->
                                                 
                                             <!-- </div>
                                        </Drawer> -->
            <!-- </a> -->
            <a class="login_reg align-right" v-class-on-scroll="{ class: 'hideLogin_reg', offset: 200 }">
                <b-dropdown
					variant="white"
					class="dropdown d-inline-block"
					toggle-class="header-item noti-icon"
					right
					menu-class="dropdown-menu-lg p-0 dropdown-menu-end user-menu"
					 v-if="isUserFetched != 0"
					>
					<template v-slot:button-content>
					<ul id="top_menu" class="drop_user">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="40" width="40" alt=""></figure><span class="userName" v-class-on-scroll="{ class: 'userNameSticky', offset: 200 }">{{userName}}</span>
								</a>
							</div>
						</li>
					</ul>
					</template>
					<simplebar style="max-height: 230px; max-width:144px;" data-simplebar>
					<div>
									<div>
										<ul class="dropdown-ul">
											<!-- <li><a href="#0"><i class="icon_cog"></i>Setting</a></li> -->
                                            <li><a href="/profile/profileDashBoard"><i class="icon_profile"></i>Profile</a></li>
											<li><a @click="logout"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
					</simplebar>
					
					</b-dropdown>
                <nav v-else>
                    <a href="#0" @click="$bvModal.hide('modal-register')" v-b-modal.modal-login class="show-submenu loginMob">Login</a>
                    <a class="show-submenu regMob" @click="$bvModal.hide('modal-login')" v-b-modal.modal-register><button class="btn_1 gradient" type="submit">Register</button></a>
                </nav>
            </a>
<!-- /Mobile view -->

            <div class="col-md-4 col-lg-4 col-xl-4 align-right noMobile" style="margin-top:10px;">
            <b-dropdown
					variant="white"
					class="dropdown d-inline-block"
					toggle-class="header-item noti-icon"
					right
					menu-class="dropdown-menu-lg p-0 dropdown-menu-end user-menu"
					 v-if="isUserFetched != 0"
					>
					<template v-slot:button-content>
					<ul id="top_menu" class="drop_user">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="40" width="40" alt=""></figure><span class="userName" v-class-on-scroll="{ class: 'userNameSticky', offset: 200 }">{{userName}}</span>
								</a>
							</div>
						</li>
					</ul>
					</template>
					<simplebar style="max-height: 230px; max-width:144px;" data-simplebar>
					<div>
									<div>
										<ul class="dropdown-ul">
											<!-- <li><a href="#0"><i class="icon_cog"></i>Setting</a></li> -->
                                            <li><a href="/profile/profileDashBoard"><i class="icon_profile"></i>Profile</a></li>
											<li v-if="canUserLogout == 0"><a @click="logout"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
					</simplebar>
					
					</b-dropdown>
					<nav class="main-menu" v-else>
					<ul>
                    <li class="submenu">
                        <a href="#0" @click="$bvModal.hide('modal-register')" v-b-modal.modal-login class="show-submenu">Login</a>
                        <!-- <a href="/login" class="show-submenu">Login</a> -->
                    </li>
                    <li class="submenu">
                        <a class="show-submenu" @click="$bvModal.hide('modal-login')" v-b-modal.modal-register><button class="btn_1 gradient" type="submit">Register</button></a>
                        <!-- <a href="/register" class="show-submenu"><button class="btn_1 gradient" type="submit">Register</button></a> -->
                    </li>
                </ul>
					</nav>
            </div>
            </div>
        </div>
    </header>
    <!-- /header -->

    
    <main>
        <div class="header-video">
            <div id="hero_video">
                <!-- <div class="shape_element one"></div>
                <div class="shape_element two"></div> -->
                <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                    <div class="container">
                        <div class="row justify-content-center text-center">
                            <div class="col-xl-7 col-lg-8 col-md-8">
                              <img src="../../templateAssests/img/cklogo.png" alt="" class="ckLogo lazy">
                                <h1>Uniquely curated culinary</h1>
                             <p></p>
                                <b-form method="post" @submit.prevent="findResLocation">
                                <div class="row no-gutters custom-search-input">
                                    <!-- <div class="col-lg-10"> -->
                                    <div>
                                        <div class="form-group">
                                            <!-- <input class="form-control no_border_r" type="text" id="autocomplete" placeholder="Select Location"> -->
                                            <vue-google-autocomplete
                                                ref="address"
                                                id="map"
                                                classname="form-control"
                                                placeholder="Please type your address"
                                                types=""
                                                v-on:placechanged="getAddressData"
                                                country="in"
                                            >
                                            </vue-google-autocomplete>
                                             <!-- <vuetify-google-autocomplete
                                                id="map"
                                                placeholder="Please type your address"
                                                v-on:placechanged="getAddressData"
                                                country="in"
                                            >
                                            </vuetify-google-autocomplete> -->
                                        </div>
                                        <button ripple class="btn_1 pulse_bt gradient findFood" type="submit"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25" v-if="searching == 1"><span v-else>Find Food</span></button>
                                    </div>
                                    <!-- <div class="col-lg-2">
                                        <button ripple class="btn_1 pulse_bt gradient" type="submit"><img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25" v-if="searching == 1"><span v-else>Find Food</span></button>
                                    </div> -->
                                </div>
                            </b-form>
                                <!-- /row -->
                                <!--<div class="search_trends">
                                    <h5>Trending:</h5>
                                    <ul>
                                        <li><a href="#0">Sushi</a></li>
                                        <li><a href="#0">Burgher</a></li>
                                        <li><a href="#0">Chinese</a></li>
                                        <li><a href="#0">Pizza</a></li>
                                    </ul>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <img src="../../templateAssests/img/video_fix.png" class="header-video--media" data-video-src="video/intro" data-teaser-source="video/intro" data-provider="" data-video-width="1920" data-video-height="960"> -->
            <video autoplay="true" loop="loop" muted="" id="teaser-video" class="teaser-video webVideo"><source src="../../templateAssests/video/intro_web_video.mp4" type="video/mp4"><source src="video/intro.ogv" type="video/ogg"></video>
            <video autoplay="true" loop="loop" muted="" id="teaser-video" class="teaser-video mobVideo"><source src="../../templateAssests/video/intro_web_mobile_video.mp4" type="video/mp4"><source src="video/intro.ogv" type="video/ogg"></video>
            <!-- <div class="wave hero"></div> -->
        </div>
        <!-- /header-video -->
        
        <!-- <div class="container main-cards">
            <div class="row cardsRow">
                <div class="col-4 col-md-3 col-lg-3">
                    <div class="item_version_2">
                        <a href="#"> -->
                        
                        <!-- <a href="/cafeterias"> -->

                            <!-- <figure class="cards">
                                <img src="../../templateAssests/img/nearby-rest.jpg" data-src="img/home_cat_pizza.jpg" alt="" class="">
                                <div class="info">
                                    <h3>Near by Restuarant</h3> -->

                                    <!-- <small>Avg price $40</small>  -->

                                <!-- </div>
                            </figure>
                        </a>
                    </div>
                </div>  
                <div class="col-4 col-md-3 col-lg-3">
                    <div class="item_version_2">
                        <a href="#">
                             -->
                        <!-- <a href="/cafeterias"> -->

                            <!-- <figure class="cards">
                                <img src="../../templateAssests/img/party-menu.jpg" data-src="img/home_cat_pizza.jpg" alt="" class="">
                                <div class="info">
                                    <h3>Party Menu</h3> -->

                                    <!-- <small>Avg price $40</small> -->

                                <!-- </div>
                            </figure>
                        </a>
                    </div>
                </div>
                <div class="col-4 col-md-3 col-lg-3">
                    <div class="item_version_2">
                        <a href="#">
                             -->
                        <!-- <a href="/restaurants/team_meeting"> -->

                            <!-- <figure class="cards">
                                <img src="../../templateAssests/img/corporate-events.jpg" data-src="img/home_cat_pizza.jpg" alt="" class="">
                                <div class="info">
                                    <h3>Corporate & Enterprises</h3> -->
                                    
                                    <!-- <small>Avg price $40</small> -->

                                <!-- </div>
                            </figure>
                        </a>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="shape_element_2" style="padding:50px 0 0 0;">
            <div class="container">
            <div class="main_title center">
                <h2 style="margin-top:0px;">For Corporate</h2>
                <p>Cum doctus civibus efficiantur in imperdiet deterruisset</p>
            </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="box_how">
                                    <figure><img src="../../templateAssests/img/how_1.svg" alt="" width="150" height="167" class="lazy"></figure>
                                    <h3>Easly Order</h3>
                                    <p>Faucibus ante, in porttitor tellus blandit et. Phasellus tincidunt metus lectus sollicitudin.</p>
                                </div>
                                <div class="box_how">
                                    <figure><img src="../../templateAssests/img/how_2.svg" alt="" width="130" height="145" class="lazy"></figure>
                                    <h3>Quick Delivery</h3>
                                    <p>Maecenas pulvinar, risus in facilisis dignissim, quam nisi hendrerit nulla, id vestibulum.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 align-self-center">
                                <div class="box_how">
                                    <figure><img src="../../templateAssests/img/how_3.svg" alt="" width="150" height="132" class="lazy"></figure>
                                    <h3>Enjoy Food</h3>
                                    <p>Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros.</p>
                                </div>
                            </div>
                        </div>
                        <p class="text-center mt-3 d-block d-lg-none"><a href="#0" class="btn_1 medium gradient pulse_bt mt-2">Register Now!</a></p>
                    </div>
                    <div class="col-lg-5 offset-lg-1 align-self-center">
                        <div class="intro_txt">
                            <div class="main_title">
                                <span><em></em></span>
                                <h2>Start Ordering Now</h2>
                            </div>
                            <p class="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed imperdiet libero id nisi euismod, sed porta est consectetur deserunt.</p>
                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <p><a href="#0" class="btn_1 medium gradient pulse_bt mt-2">Register</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- /shape_element_2 -->

        <!-- <div class="bg_gray">
            <div class="container margin_60_40"> -->
                <!-- /row -->
                <!-- <div class="banner lazy" :style="{backgroundImage: 'url('+require('../../templateAssests/img/delivery-banner.jpg')+')'}">
                    <div class="wrapper d-flex align-items-center opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.3)">
                        <div>
                            <small>FooYes Delivery</small>
                            <h3>We Deliver to your Office</h3>
                            <p>Enjoy a tasty food in minutes!</p>
                            <a href="grid-listing-filterscol.html" class="btn_1 gradient">Start Now!</a>
                        </div>
                    </div> -->
                    <!-- /wrapper -->
                <!-- </div> -->
                <!-- /banner -->
            <!-- </div>
        </div> -->
        <!-- /bg_gray -->

    </main>
  <!-- /main -->


  

<loginRegModal />
<termsAndCondition />
<footerLayout style="display:block!important;"/>
    </div>
    <!-- /Root Div  -->
    
</template>

<!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/home.css"></style>
<style scoped>
.headerCk
{
    position: fixed;
}
.contact{
        font-size:14px;
        color:#fff;
    }
    .contactAnch{
        color:#fff;
    }
    .contactSticky{
      color:#000!important;
      /* margin-top:15px; */
    }
    .contactSticky .contactAnch{
      color:#000;
    }
    .anchor:hover, .anchor:focus{
        color:#e54750;
    }
    .a.btn_1:hover, .btn_1:hover{
        color:#fff;
    }
    .userNameSticky{
        color:#000!important;
    }
    .main-cards{
        position:relative;
        top:-145px;
        z-index:9;
        /* z-index:100; */
    }
    .cards{
        max-width:222px;
        max-height:245.43px;
        border-radius:5px;
    }
    .cardsRow{
        justify-content:center;
    }
    .opacity-mask{
        background-color:rgba(0, 0, 0, 0.6);
    }
    .ckLogo {
          bottom: 43px;
           position: relative;
           object-fit: cover;
           height: 100px;
    }
    #logo{
        padding-top:14px;
    }
    .mainLogoImg{
        width: 100%;
        object-fit: contain;
    }
    .mainLogoShow{
        display: block!important;
        margin-top: 10px;
    }
    .mainLogoHide{
        display: none;
    }
    .modal-body{
        padding:30px!important;
    }
    .modalLoginText{
        font-size:24px;
        margin-bottom: 14px;
        color:#212121;
    }
    .emailInput{
        margin-bottom: px;
    }
    .otpBtn{
        height: 45px;
        background: #FFc60c;
        border: none;
        color: #212121;
        margin-bottom: 37px;
    }
    .divider{
        color:#eaeaea;
        margin-bottom: 29px;
    }
    .google img{
        margin-right: 28px;
    }
    .google{
        border: 1px solid #EAEAEA;
        box-sizing: border-box;
        border-radius: 5px;
        color:#212121;
        font-size: 14px;
    }
    a.social_bt.google:hover, a.social_bt.google:focus{
        border: 1px solid #777777;
    }
    .googleLoginBtn
    {
        width: 100%;
        background: transparent;
        border:none;
    }
    .signUptext{
        color: #212121;
        font-size: 14px;
    }
    .signUptext span a{
        color: #F3766A!important;
    }
    .modal-footer{
        height:50px!important;
    }
    .otpSection{
        margin-top: 40px;
    }
    .otpText{
        margin-bottom: 10px;
        color: #212121;
        font-size:14px;
    }
    .contactNumber{
        font-size:16px;
        color:#212121;
        margin-bottom: 17px;
    }
    .contactNumber a{
         color: #F3766A!important;
         font-size:13px;
    }
    .enterOTP{
        color:#777777!important;
        font-size:14px;
        margin-bottom:14px;
    }
    .otpInputs{
        margin-bottom: 16px;
    }
    .resendOTP{
        color: #777;
        margin-bottom: 15px;
         width:80%;
    }
    .resendOTP .timer{
        color: #F3766A;
       
    }
    .verifyotpBtn{
        width: 170px;
        height: 45px;
        background: #FFC60C;
        border-radius: 5px;
        border:none;
        margin-bottom: 19px;
    }
    #signUpConditions li{
        display:inline;
        margin-right: 10px;
        font-size: 10px;
        text-decoration: underline;
    }
    header{
        z-index: 101!important;
    }
    .closeModalDiv{
        float: right;
        padding-top: 6px;
        padding-right: 9px;
        cursor: pointer;
    }
    .inner-addon {
        position: relative;
    }
    .bxSearchModal{
        position: absolute;
        padding: 10px;
        pointer-events: none;
    }
    .errorDiv{
        font-size:14px;
        color: red;
    }
    .right-addon .bxSearchModal { right: 8px;font-size:22px;}
    .right-addon input { padding-right: 30px; border-radius: 20px;}
    /* @media screen and (max-width: 767px) {
           .conatiner{
             padding-left:0;
             padding-right:0;
           }
    } */
    </style>
    <style>
    .dropdown-toggle{
	margin-right: 10px!important;
    }
    .dropdown-toggle::after{
        display: none !important;
    }
    .dropdown-menu{
	    border-top: 3px solid #A4827D;
		width: 330px!important;
		max-width: 330px!important;
    }
    .user-menu{
        width: 144px!important;
        max-width: 144px!important;
        right: auto !important;
    }
    .dropdown-ul
    {
        list-style: none;
        padding: 0px!important;
    }
    .dropdown-ul li{
        padding: 12px 15px;
        color: #000;
    }
    .dropdown-ul li a{
        color: #000;
    }
    .dropdown-ul li a i{
        margin-right: 8px;
    }
    ul.dropdown-ul li a{
	    cursor: pointer;
    }
    .userPlaceImage
{
	object-fit: contain;
	border-radius: 25px;
}
.loginReg
{
	font-size: 18px;
	color: #F3766A;
}
.head_section
{
	margin-bottom: 20px;
}
/* .navigation_ul
{
	list-style-type: none;
	padding: 0;
} */
.navigation_ul li
{
	padding: 12px 0;
	font-size: 14px;
}
.navigation_ul li a
{
	color: #777;
}
.helpHead
{
	font-size: 16px;
	color: #777;
}
.login_reg
{
    display: none;
}
.login_reg a
{
    padding: 0 8px 10px;
}
.loginMob
{
    color: #fff;
}
.loginMob:hover, .loginMob:focus
{
    color: #e54750!important;
}
.hideLogin_reg
{
    display: none !important;;
}
.findFood
{
    max-width: 122px;
    position: absolute!important;
    top: 0px;
    right: 5px;
}
@media (max-width: 991px)
{
    .login_reg
    {
        display: block;
        position: absolute;
        left: 0px;
        top: 27px;
    }
    .findFood
    {
        margin: 0px 0 0 0!important;
        top: 5px;
        right: 15px;
    }
}
    </style>
